import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=064e717c&"
import script from "./Container.vue?vue&type=script&lang=js&"
export * from "./Container.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('064e717c')) {
      api.createRecord('064e717c', component.options)
    } else {
      api.reload('064e717c', component.options)
    }
    module.hot.accept("./Container.vue?vue&type=template&id=064e717c&", function () {
      api.rerender('064e717c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/Container.vue"
export default component.exports